import { isInIframe } from '@uc/compass-app-bridge';
import authApi from 'src/api/auth-api';
import contextualApi from 'src/api/contextual-api';
import logger from 'src/logger';
import logoutClear from 'src/utils/logout-clear';
import { setToken } from 'src/utils/token';

function setGlideContext(
  Glide: typeof window.Glide,
  valuesJson: typeof window.Glide
) {
  const values =
    typeof valuesJson === 'object'
      ? valuesJson
      : JSON.parse(valuesJson || '{}');
  const keys = Object.keys(values);
  for (let i = 0; i < keys.length; i++) {
    Glide[keys[i]] = values[keys[i]];
  }
}

function setGtag() {
  if (window.gtag && window.Glide.user && window.Glide.user.id) {
    window.gtag('set', { user_id: window.Glide.user.id });
  }
}

function setAuthBearer(token: string) {
  window.Glide.authBearer = token;
}

function gtag(...args: unknown[]) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(...args);
}

type View = 'login' | 'logout' | 'register' | 'index' | '';
function getMatchedView(): View {
  if (location.pathname.indexOf('/auth/login') === 0) {
    return 'login';
  }
  if (location.pathname.indexOf('/auth/register') === 0) {
    return 'register';
  }
  if (location.pathname.indexOf('/auth/logout') === 0) {
    return 'logout';
  }
  if (location.pathname === '/') {
    return 'index';
  }

  return '';
}

export default async function bindContext() {
  window.Glide = { ...window.Glide };
  window.Glide.IS_FE_BE_SPLIT = true;
  window.history.scrollRestoration = 'manual';
  window.setGlideContext = setGlideContext;
  window.setGtag = setGtag;
  window.setAuthBearer = setAuthBearer;

  // TODO: Move redirection logic to front-end.
  const view = getMatchedView();
  const searchParams = new URLSearchParams(location.search.slice(1));
  if (view === 'login' && searchParams.get('token')) {
    try {
      const loginResp = await authApi.jwtLogin({
        token: searchParams.get('token') ?? undefined,
        next: searchParams.get('next') ?? undefined,
      });
      setToken(loginResp.data.token, true);

      window.location.assign(loginResp.data.next);
    } catch (error) {
      logger.error(error);

      window.location.assign('/auth/login/');
    } finally {
      /**
       * `window.location.assign` will make the browser refresh and redirect to next route path,
       * if not return a false, the rest logic will also continue to be executed.
       * In a general Chrome it will works well, but in M1 chrome it can’t works,
       * and will cancel the redirection request. So here need return a false.
       */
      return false;
    }
  } else if (view === 'logout') {
    await authApi.logout();
    logoutClear();
  } else if (view) {
    // TODO: Remove this after set jwt token to cookies
    const params = { view, mode: 'development' } as { view: View; mode: 'development' | undefined };
    if (process.env.NODE_ENV === 'development') {
      params.mode = process.env.NODE_ENV;
    }
    const { next, cookies_auth } = await authApi.getNextUrl(
      params,
      location.search
    );

    if (!cookies_auth) {
      logoutClear();
    }

    if (next?.[0]?.url) {
      window.location.assign(next[0].url);

      return false;
    }
  }

  const Glide = window.Glide;
  const [configRes, constRes] = await Promise.all([
    contextualApi.configurations(),
    contextualApi.constants(),
  ]);

  Glide.CONSTANTS = constRes?.data || {};
  if (configRes?.data) {
    const {
      environments,
      services,
      keys,
      session_data: sessionData,
    } = configRes.data;
    Glide.LAUNCHDARKLY_BOOTSTRAP_FLAGS = environments.FLAGS;
    Glide.PUSHER_KEY = keys.PUSHER_KEY;
    Glide.FILESTACK_KEY = keys.FILESTACK_KEY;
    Glide.RELEASE = keys.RELEASE;
    Glide.LATEST_RELEASE = keys.LATEST_RELEASE;
    Glide.STRIPE_KEY = keys.STRIPE_KEY;
    Glide.STRIPE_INS_KEY = keys.STRIPE_INS_KEY;
    Glide.INTERCOM_APP_ID = keys.INTERCOM_APP_ID;
    Glide.FILESERVICE = services.FILESERVICE;
    Glide.PSPDFKIT_URL = services.PSPDFKIT_URL;
    Glide.PSPDFKIT_LICENSE_KEY = keys.PSPDFKIT_LICENSE_KEY;
    Glide.DATADOG_APPLICATION_ID = keys.DATADOG_APPLICATION_ID;
    Glide.DATADOG_CLIENT_TOKEN = keys.DATADOG_CLIENT_TOKEN;
    Glide.DATADOG_SITE = keys.DATADOG_SITE;
    Glide.DATADOG_ENV = keys.DATADOG_ENV;
    Glide.SESSION_CHANNEL = services.SESSION_CHANNEL || ''; // Need confirm with backend
    Glide.ALL_FLAGS = keys.ALL_FLAGS;

    setGlideContext(window.Glide, sessionData);

    // Do not initialize Intercomm when Glide is embedded within iframe
    // regardless of the value in `ENABLE_INTERCOM` config
    if (!isInIframe() && keys.ENABLE_INTERCOM) {
      window.intercomSettings = {
        app_id: Glide.INTERCOM_APP_ID,
        hide_default_launcher: true,
      };
      (function injectIntercom() {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i = function i(...args: unknown[]) {
            i.c(args);
          };
          i.q = [] as unknown[];
          i.c = function c(args: unknown) {
            i.q.push(args);
          };
          w.Intercom = i;
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/xy6g7xqh';
          const x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
        }
      })();
    } else {
      window.Intercom = function Intercom() {};
    }

    if (Glide.env === 'production') {
      // retently nps
      const retentlyScript = document.createElement('script');
      retentlyScript.innerHTML = `
        (function (d, s, id) {
          var js, rjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://cdn.retently.com/public/components/embed/sdk.min.js";
          rjs.parentNode.insertBefore(js, rjs);
        }(document, 'script', 'retently-jssdk'));
      `;
      document.head.appendChild(retentlyScript);

      // pingdom
      const pingdomScript = document.createElement('script');
      pingdomScript.src =
        '//rum-static.pingdom.net/pa-5a74b2f80b34680007000197.js';
      document.body.appendChild(pingdomScript);

      // Global site tag (gtag.js) - Google Analytics
      const gtagScript = document.createElement('script');
      gtagScript.src =
        'https://www.googletagmanager.com/gtag/js?id=UA-110819230-1';
      document.body.appendChild(gtagScript);
      gtag('js', new Date());
      gtag('config', 'UA-110819230-1');
      window.gtag = gtag;
    } else {
      window.fbq = function fbq() {};
      window.gtag = function gtag() {};
    }

    if (keys.ALL_FLAGS) {
      window.Glide.LAUNCHDARKLY_BOOTSTRAP_FLAGS = keys.ALL_FLAGS;
    }

    if (keys.LAUNCHDARKLY_FRONTEND_KEY) {
      window.LAUNCHDARKLY_FRONTEND_KEY = keys.LAUNCHDARKLY_FRONTEND_KEY;
    }
    window.handleGapiLoad = function handleGapiLoad() {};
  }

  if (Glide.user) {
    window.setGtag();
  }

  const callAfterAppLoaded = () => {
    const user = Glide.user;
    if (user && user.contact) {
      const div = document.createElement('div');
      div.setAttribute('id', 'retently-survey-embed');
      div.setAttribute(
        'data-href',
        'https://app.retently.com/api/remote/tracking/5d55bb1a34f7df2930e44fd4'
      );
      div.setAttribute('data-rel', 'dialog');
      div.setAttribute('data-campaign', 'regular');
      div.setAttribute('data-email', `${user.contact.email}`);
      div.setAttribute('data-firstname', `${user.contact.firstName}`);
      div.setAttribute('data-lastname', `${user.contact.lastName}`);
      div.setAttribute('data-tags', 'inapp');
      document.body.appendChild(div);
    }

    if (Glide.env === 'production') {
      const linkedinScript = document.createElement('script');
      linkedinScript.innerHTML = `
        _linkedin_partner_id = "2736348";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `;
      document.body.appendChild(linkedinScript);

      const lintrkScript = document.createElement('script');
      lintrkScript.innerHTML = `
        (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
      `;
      document.appendChild(lintrkScript);

      const linkedinImgContainer = document.createElement('noscript');
      const linkedinImg = document.createElement('img');
      linkedinImg.height = 1;
      linkedinImg.width = 1;
      linkedinImg.style.display = 'none';
      linkedinImg.alt = '';
      linkedinImg.src =
        'https://px.ads.linkedin.com/collect/?pid=2736348&fmt=gif';
      linkedinImgContainer.appendChild(linkedinImg);
      document.body.appendChild(linkedinImgContainer);
    }
  };

  return callAfterAppLoaded;
}
