import 'src/entries/polyfills';
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import PreloadSpin from 'src/components/common/preload-spin';
import init from 'src/entries/init';
import bindContext from './entries/bindContext';

const routes = [
  {
    name: 'templates-and-clauses',
    match: /^\/templates-and-clauses/,
  },
  {
    name: 'auth',
    match: /^\/auth/,
  },
  {
    name: 'admin',
    match: /^\/-site-admin/,
  },
  {
    name: 'public',
    match: /^\/(quit|shared|public)/,
  },
  {
    name: 'client',
    match: /^\/client/,
  },
  {
    name: 'signing',
    match: /^\/sign/,
  },
  {
    name: 'app',
    match: /^\//,
  },
];

async function bootstrap() {
  const container = document.getElementById('root');
  if (!container) {
    return;
  }

  ReactDOM.render(<PreloadSpin />, container);

  const callAfterAppLoaded = await bindContext();

  if (callAfterAppLoaded) {
    init();

    const App = await getMatchedApp();
    if (App) {
      ReactDOM.unmountComponentAtNode(container);
      ReactDOM.render(<App />, container);

      callAfterAppLoaded();
    }
  }
}

async function getMatchedApp() {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      route.match.test(location.pathname) &&
      location.pathname.indexOf('.html') === -1
    ) {
      return import(`src/entries/${route.name}/root`).then(
        (mod) => mod.default
      );
    }
  }
  return false;
}

bootstrap();
