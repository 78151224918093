import React from 'react';
import classNames from 'classnames';
import { isInIframe } from '@uc/compass-app-bridge';


export const PreloadSpin: React.FC<unknown> = () => {
  return (
    <div
      id="preload-spin"
      className={classNames('preload-spin-main', { compass: isInIframe() })}
    >
      <div className="preload-spin-container">
        <span className="preload-spin">
          <i className="spin-dot" style={{ top: 0, left: 0 }}></i>
          <i
            className="spin-dot"
            style={{ animationDelay: '0.4s', top: 0, right: 0 }}
          ></i>
          <i
            className="spin-dot"
            style={{ animationDelay: '0.8s', bottom: 0, right: 0 }}
          ></i>
          <i
            className="spin-dot"
            style={{ animationDelay: '1.2s', bottom: 0, left: 0 }}
          ></i>
        </span>
      </div>
    </div>
  );
};
